@tailwind base;
@tailwind components;
@tailwind utilities;

<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=roboto&display=swap" rel="stylesheet">


.form-container {
  @apply relative bg-gray-100 px-2 pb-1 rounded border border-gray-200;
}

.form-input {
  @apply block pl-6 bg-transparent border-0 w-full;
}

.form-icon {
  @apply absolute bottom-[4px] left-2;
}
