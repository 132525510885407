#event-edit {
  background: #fff;

  h3 {
    color: #000;
    font-size: 17px;
    margin-top: 32px;
  }

  h5 {
    color: #7a7a7a;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  h6 {
    color: #303030;
    font-size: 12px;
    font-family: 'Work Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

  p {
  }

  .form-control,
  .form-select {
    border-radius: 4px;
    color: #474747;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: 'slnt' 0;
    height: 36px;
  }

  .form-label {
    color: #8b8b8b;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: 'slnt' 0;
  }

  .ql-editor,
  textarea {
    min-height: 100px;
    resize: vertical;
  }

  input[type='date'],
  input[type='time'] {
    position: relative;

    &::-webkit-calendar-picker-indicator {
      background-position: right;
      background-size: 20px;
      color: transparent;
      cursor: pointer;
      padding: 0 16px 0 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .text-danger {
    font-family: 'Work Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
  }

  #continue {
    background: #8023eb;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    height: 36px;
    margin-bottom: 40px;
    width: 100%;

    &:hover {
      background: #8023eb;
    }
  }

  #book-event:hover {
    background: #8023eb;
  }

  .file-upload {
    height: 183px;
    background: rgba(189, 25, 30, 5%);
    border-radius: 4px;
    text-align: center;
    padding: 2rem;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    h3 {
      color: #8023eb;
      font-family: 'Work Sans', sans-serif;
      font-optical-sizing: auto;
      font-weight: 500;
      font-size: 16px;
      font-style: normal;
      margin-bottom: 0;
      margin-top: 10px;
    }

    p {
    }

    input {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  .btn-add {
    border: 1px solid #8e929c;
    color: #8e929c;
    height: 40px;
    margin: 16px 0;
    width: 93px;

    &:hover {
      background: #8023eb;
      color: #fff;
    }
  }

  @media (min-width: 992px) {
    .form-control,
    .form-select,
    .form-check-label {
      font-size: 16px;
    }
  }

  .file-upload.drag-over {
    border: 2px dashed #bc0000;

    background-color: #f0f8ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .thumbnail {
    height: 50px;
  }

  .uploading-text {
    color: #303030;
    font-family: 'Work Sans', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
  }
}
