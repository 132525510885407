#ticket-scan {
  background: #fff;
  color: #171a1f;
  min-height: 100vh;

  #logo {
    height: 47px;
  }

  h2 {
    font-size: 24px;
  }

  #success-msg {
    h2 {
      color: #171a1f;
      line-height: 36px;
    }

    h3 {
      color: #171a1f;
      font-size: 25px;
      line-height: 48px;
    }
  }

  p {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  #ticket-scan p {
    font-size: 18px;
  }
}
