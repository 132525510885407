#tickets {
  background: #fff;
  color: #171a1f;
  min-height: 100vh;

  header {
    h2 {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .custom-tabs {
    display: flex;
  }

  .tab {
    color: #9095a0;
    font-size: 14px;

    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings: 'slnt' 0;

    line-height: 22px;
    padding: 0;
    cursor: pointer;
    border-bottom: 1px solid transparent;
  }

  .tab.active,
  .tab:hover {
    color: #323842;
    border-bottom-color: #323842;
  }

  .tab-content {
    margin-top: 20px;
  }

  .ticket {
    background: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px 0;
    border-radius: 4px;

    h3 {
      border-bottom: 3px solid #565e6c;
      color: #171a1f;
      display: inline-block;
      font-size: 14px;
    }

    h3,
    p {
      font-family: 'Inter', sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-variation-settings: 'slnt' 0;
    }

    p {
      color: #171a1f;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 10px;
    }

    a {
      color: #0d78c9;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .ticket-image {
    margin-right: 15px;

    img {
      border-radius: 4px;
      width: 155px;
      height: 89px;
    }
  }
}

@media (min-width: 992px) {
  #tickets {
    h3 {
      font-size: 16px !important;
    }

    p {
      font-size: 14px !important;
    }
  }
}
