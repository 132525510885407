#nav {
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  color: #bebebe;
  z-index: 1001;

  svg,
  img {
    fill: #bebebe;
    height: 20px;
    margin-bottom: 10px;
  }

  svg.large-icon {
    height: 30px;
  }

  i {
    height: 25px;
  }

  .btn {
    color: #6a6969;
    font-size: 12px;

    &:active {
      border-color: transparent;
    }

    &.active {
      color: #8023eb;
      border-color: transparent;

      svg {
        fill: #8023eb;
      }
    }
  }
}

@media (min-width: 992px) {
  #nav button {
    font-size: 16px;
  }
}
