.border-bottom-primary {
  border-bottom: 0.5px solid #0d6efd;
}

.next-btn {
  background-color: #65568f;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

input[type='radio'] {
  accent-color: #65568f;
}

.add-picture {
  cursor: pointer;
}

.instructions {
  font-size: smaller;
  color: darkgray;
}
