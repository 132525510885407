#reset-password {
  height: calc(100vh - 94px);

  h2 {
    color: #8023eb;
    font-size: 20px;
  }

  h3 {
    color: #000;
    font-size: 25px;
  }

  .form-control,
  .form-select {
    background: #f5f5f5;
    border-radius: 4px;
    color: #474747;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: 'slnt' 0;
    height: 36px;
  }

  .form-check-label {
    color: #474747;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: 'slnt' 0;
  }

  .form-label {
    display: none;
  }

  #continue {
    background: #8023eb;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    height: 36px;
    margin-bottom: 40px;
    width: 100%;

    &:hover {
      background: #8023eb;
    }
  }

  #book-event:hover {
    background: #8023eb;
  }

  @media (min-width: 992px) {
    .form-control,
    .form-select,
    .form-check-label {
      font-size: 16px;
    }
  }
}
