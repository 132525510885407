.thumbnail-container {
  display: flex;
  gap: 10px;
}

.thumbnail-container img {
  width: 150px;
  height: auto;
  cursor: pointer;
}

.full-image-container img {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

#flyer-upload-button {
  background: #f24c40;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  height: 36px;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;

  hover {
    background: #d94338;
  }
}
